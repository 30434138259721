<template>
  <div style="margin: 10px;">
    <b-row>
      <b-col sm="12" lg="1"></b-col>
      <b-col sm="12" lg="8">
        <work-subject :p_showDetails="{'type': 'selected', 'subject_name': 'unittreatmentlist'}"></work-subject>
      </b-col>
      <b-col sm="12" lg="3"></b-col>
    </b-row>
  </div>
</template>

<script>
import {
  default as WorkSubject
} from '@/components/widgets/WorkSubject';
import {
  default as TreatmentListWidget
} from '@/components/widgets/TreatmentListWidget';
import {
  default as SqlDatabase
} from '@/components/widgets/SqlDatabase';
import { mapGetters } from 'vuex';
export default {
  name: 'TreatmentList',
  computed: mapGetters({
    // lang: 'lang'
  }),
  components: {
    WorkSubject,
    TreatmentListWidget,
    SqlDatabase,
  },
  props: {},
  data () {
    return {
      d_treatmentList: require('@/example_data/treatment_list').example_data,
      d_patientData: require('@/example_data/patient_data').example_data,
      d_treatmentData: {
        'selected': ''
      },
      d_componentExample: {
        'schema_drugs': [
          { 'code': '<treatment-list-widget :p_treatmentList="d_treatmentList" :p_treatmentData="d_treatmentData"></treatment-list-widget>', 'name': 'Örnek komponent kullanımı' },
        ]
      },
    };
  },
  created: function () {},
  beforeMount () {},
  mounted: function () {},
  methods: {},
  watch: {}
}

</script>

<style type="text/css">


</style>

